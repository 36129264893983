@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";

    @font-face {
        font-family: "Montserrat";
        src: url("./Fonts/HankenGrotesk-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat-Medium";
        src: url("./Fonts/HankenGrotesk-Medium.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat-Bold";
        src: url("./Fonts/HankenGrotesk-Bold.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat-Semi";
        src: url("./Fonts/HankenGrotesk-SemiBold.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat-Light";
        src: url("./Fonts/HankenGrotesk-Light.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
.slide-in-top {
    -webkit-animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-out-top {
	-webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-18 2:56:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes slide-out-top {
     0% {
       -webkit-transform: translateY(500px);
               transform: translateY(500px);
       opacity: 0;
     }
     100% {
       -webkit-transform: translateY(0);
               transform: translateY(0);
       opacity: 1;
     }
   }
   @keyframes slide-out-top {
     0% {
       -webkit-transform: translateY(500px);
               transform: translateY(500px);
       opacity: 0;
     }
     100% {
       -webkit-transform: translateY(0);
               transform: translateY(0);
       opacity: 1;
     }
   }
  
  

.reg{
    padding: 7.92593px 12.6815px;
    background: #C734E5;
    border-radius: 16px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.158519px;
}


.l-in{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #AF7BA3;
    padding: 7.92593px 12.6815px;
}

.Loading {
    position: relative;
    display: inline-block;
    width: 96px;
    height: 3px;
    background: rgba(175, 123, 163, 0.26);
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    overflow: hidden;
}
  
.Loading:after {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    animation: load 5s infinite;
}

.cnvrt{
    border-radius: 5px;
    background-color: '#F7F7FC';
    box-shadow: '0 2px 4px rgba(0, 0, 0, 0.1)';
    word-wrap: 'break-word';
    
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
        from var(--border-angle),
        #FFF,
        #FFF 5%,
        #FFF 60%,
        #FFF 95%
      );
    
    border: solid 1px transparent;
    --gradient-border: conic-gradient(from var(--border-angle), transparent, #C734E5 25%, #C734E5 50%, transparent);
    
    background: 
      // padding-box clip this background in to the overall element except the border.
      var(--main-bg) padding-box,
      // border-box extends this background to the border space
      var(--gradient-border) border-box, 
      // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
  
    &:hover {
      animation-play-state: paused;
    }
}

.text-12{
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.2px;
    color: #201b37;
}
  
@keyframes load {
0% {
    width: 0;
    background: #C734E5;
}

25% {
    width: 40%;
    background: #C734E5;
}

50% {
    width: 60%;
    background: #C734E5;
}

75% {
    width: 75%;
    background: #C734E5;
}

100% {
    width: 100%;
    background: #C734E5;
}
}

.Montserrat{
    font-family: "Montserrat";
}
.Montserrat-Medium{
    font-family: "Montserrat-Medium";
}
.Montserrat-Bold{
    font-family: "Montserrat-Bold";
}
.Montserrat-Semi{
    font-family: "Montserrat-Semi";
}
.Montserrat-Light{
    font-family: "Montserrat-Light";
}
.ubuntu{
    font-family: 'Ubuntu', 'Montserrat';
}
.Lato{
    font-family: 'Lato', sans-serif;
}
.bgl1{
    background-color: #C4C4C4;
}
.bgp1{
    background-color: #201b37;
}
.bgp2{
    background-color: rgba(175, 123, 163, 0.1);
}
.bgl2{
    background-color: #E5E5E5;
}
.bgl3{
    background-color: #F8F9FC;
}
.bgl4{
    background-color: #F5F6FA;
}

.bbl{
    background: #E2F4F8;
}

.pr-bg{
    background-image: url("./assets/profilebg.svg");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.td1{
    color: #201b37;
}
.tp1, .table-responsive td.text-primary{
    color: #C734E5;
}
.tp2{
    color: #11142D;
}
.tl{
    color: #FAFAFA;
}
.tl1{
    color: #9A9AB0;
}
.tl2{
    color: #717579;
}
.tl3{
    color: #AAB2C8;
}

.bgdp {
    background-color: #201b37;
}
.bgd1 {
    background-color: #2E2E2E;
}
.bgd2 {
    background-color: #404040;
}
.bgd3 {
    background-color: #535353;
}
.bgd4 {
    background-color: #666666;
}
.bbd {
    background: #1F1F1F;
}
.td {
    color: #050505; /* Inverted from #FAFAFA */
}
.td1 {
    color: #65656F; /* Inverted from #9A9AB0 */
}
.td2 {
    color: #8E8B86; /* Inverted from #717579 */
}
.td3 {
    color: #555738; /* Inverted from #AAB2C8 */
}

.w-120{
    width: 120px;
}
.w-277{
    width: 277px;
}
.h-52vh{
    height: 52vh;
}
.h-80vh{
    height: 80vh;
}
.lp-bg{
    background-image: url(./assets/Image.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // @media screen and (min-width: 720px) {
    // }

}
.tms{
    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.0604447px;
    color: #2E2E2E;
}
.t2{
    font-size: 12px;
    line-height: 22px;
}

.lh30{
    line-height: 30px;
}
.selections{
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.0604447px;
    color: #52575C;
}

.disabled{
    background: #A0A4A8 !important;
    color: #FFFFFF !important;
    cursor: not-allowed;
    border: none !important;
}

.so{
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.0604447px;
    color: #52575C;
}
.odds{
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.0604447px;
    color: #4FC143;
}
.card{
    background: #008599;
    box-shadow: 0px 2.32289px 3.48434px rgba(62, 73, 84, 0.04);
    border-radius: 11.6145px;
    font-family: "Montserrat";
}

.card h1{
    font-weight: bold;
    font-size: 8px;
    line-height: 15px;
    letter-spacing: 0.183092px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.9);
}
.mobile-hide{
    @media screen and (max-width: 600px) {
        display: none;
    }
}
.mobile-show{
    @media screen and (min-width: 600px) {
        display: none;
    }
}

.courier{
    font-family: 'Courier Prime', monospace;
}

.card p{
    font-size: 10px;
    font-family: 'Courier Prime', monospace;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.183092px;
    color: #FFFFFF;
}

.bg-crd_b,.bg-crd_o,.bg-crd_p{
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}
.bg-crd_b{
    background-image: url(./assets/crd-b.svg) !important;
}
.bg-crd_o{
    background-image: url(./assets/crd-o.svg) !important;
}

.bg-crd_p{
    background-image: url(./assets/crd-p.svg) !important;
}

.addBankCard{
    font-family: "Montserrat";
    background: #EDEFF8;
    border: 1.33814px dashed #9594A0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 286.8px;
    height: 190.82px;
    padding: 20px;
    border-radius: 16.977px;
    color: #000000;
    // background: #263884;
    box-shadow: 0px 3.39541px 5.09311px rgba(62, 73, 84, 0.04);
        h1{
            font-size: 17.3958px;
            line-height: 21px;
            letter-spacing: 0.267628px;
        }
        h2{
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.267628px;
            color: #000000;
        }
        p{
            font-family: "Montserrat-Medium";
            font-size: 14px;
            line-height: 14px;
            color:#000000;
        }
}

.card3{
    width: 286.8px;
    height: 190.82px;
    padding: 20px;
    border-radius: 16.977px;
    color: #FFFFFF;
    // background: #263884;
    box-shadow: 0px 3.39541px 5.09311px rgba(62, 73, 84, 0.04);
        h1{
            font-size: 17.3958px;
            line-height: 21px;
            letter-spacing: 0.267628px;
        }
        h2{
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.267628px;
            color: rgba(255, 255, 255, 0.6);
        }
        p{
            font-family: Courier;
            font-weight: bold;
            font-size: 11.8839px;
            line-height: 14px;
            color: #FBFBFB;
        }
}

.cardExpired {
    opacity: 0.7; /* Semi-transparent to indicate expiration */
    width: 286.8px;
    height: 190.82px;
    padding: 20px;
    border-radius: 16.977px;
    color: #000000;
    // background: #263884;
    box-shadow: 0px 3.39541px 5.09311px rgba(62, 73, 84, 0.04);
        h1{
            font-size: 17.3958px;
            line-height: 21px;
            letter-spacing: 0.267628px;
            color: rgba(255, 255, 255, 0.6);
        }
        h2{
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.267628px;
            color: rgba(255, 255, 255, 0.6);
        }
        p{
            font-family: Courier;
            font-weight: bold;
            font-size: 11.8839px;
            line-height: 14px;
            color: #FBFBFB;
        }
        
    /* Overlay to indicate expiration */
    &:after {
        content: "EXPIRED";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg); /* Center and rotate the text */
        font-size: 24px;
        font-weight: bold;
        color: rgba(255, 0, 0, 0.8); /* Red color for visibility */
        text-transform: uppercase;
        pointer-events: none; /* Make sure the overlay doesn't block interactions */
    }
}

.pill{
    background: #FFFFFF;
    border: 1px solid rgba(175, 123, 163, 0.33);
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 13px;
    line-height: 24px;
}

.pill2{
    background: #F8F8F8;
    border: 1px solid #BCBBC3;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 13px;
    line-height: 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.lgt-txt{
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    font-variant: small-caps;
    color: #000000;
    opacity: 0.2;
    text-transform: uppercase;
}

.text-gyt{
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.164637px;
    color:  rgba(0, 0, 0, 0.5);
    background: #FFFFFF;
}

.info-card{
    background: #F8F9FB;
    border: 0.2px solid #AF7BA3;
    border-radius: 5px;
    padding: 9px 20px ;
    height: 69px;

    h4{
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.3px;

        color: #000000;
    }

    .dmctn{
        border-left: 1px solid #C4C4C4;
        p,h4{
            color: rgba(199, 52, 229) !important;
        }
    }
}

.switch{
    background: #E8E8E8;
    opacity: 0.3;
    border-radius: 30px;
    width: 17px;
    height: 35px;
}

.card2{
    font-family: "Montserrat";
    background: #EDEFF8;
    border: 1.33814px dashed #9594A0;
    box-sizing: border-box;
    border-radius: 10.7051px;
}

.card2 p{
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.136825px;
    color: #9594A0;
}

.w-520{
    width: 520px;
}

.auth-bg{
    background-image: url(./assets/Image2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // @media screen and (min-width: 720px) {
    // }

}

.error-bg{
    background-image: url(./assets/404bg.jpg);
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    // @media screen and (min-width: 720px) {
    // }

}

.text-err{
    color: rgba(0, 0, 0, 0.59);
}

.m-a{
    margin-left: auto;
    margin-right: auto;
}

.mt-a{
    margin-top: auto;
    margin-bottom: auto;
}

.mtp-a{
    margin-top: auto;
}

.ml-a{
    margin-left: auto;
}

.mt-full{
    margin-top: 100%;
}

.br-20{
    border-radius: 20.1627px;
}

.br-16{
    border-radius: 16px;
}

.br-8{
    border-radius: 8px;
}

.br3d{
    border-radius: 3.17037px;
}

.bgrl{
    background: rgba(232, 232, 232, 0.26);
}

.bgpop{
    background: rgba(175, 123, 163, 0.18)
}

.table-br{
    border: 0.815476px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 2.44643px;
    font-weight: 500;
}

.fltr{
    background: #FFFFFF;
    height: 30px;
    width: 91px;
    border: 1px solid #E8EEF1;
    padding: 9px 17px;
    box-sizing: border-box;
    box-shadow: 0px 2.76263px 3.68351px -0.920878px rgba(0, 39, 58, 0.03);
    border-radius: 5.52527px;
    margin-top: 7px;

    p{
        font-weight: 600;
        font-size: 9.78571px;
        line-height: 15px;
        letter-spacing: 0.21746px;
        color: #4A5D75;
        margin: 0px 0px 0px 5px;
    }
}

.filter{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 16px 16px 16px 18px;
    z-index: 400;

    p{
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #C734E5;
        // margin: 0px;
        background: #FFFFFF;
        cursor: pointer;
        display: flex;

        span {
            margin-right: 13px;
        }

        &:hover{
            background: #F9F9F9;
        }
    }
}

.filter2{
    background: #FFFFFF;
    border-radius: 5px;
    z-index: 400;

    p{
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.2px;
        // margin: 0px;
        background: #FFFFFF;
        cursor: pointer;
        display: flex;

        span {
            margin-right: 13px;
        }

        &:hover{
            background: #F9F9F9;
        }
    }
}

.header-pop-up{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 16px 16px 16px 18px;
    z-index: 999;

    p{
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #C734E5;
        // margin: 0px;
        background: #FFFFFF;
        cursor: pointer;
        display: flex;

        span {
            margin-right: 13px;
        }

        &:hover{
            background: #F9F9F9;
        }
    }
}

.tab{
    height: 28px;
    background: #F7F7FC;
    border-radius: 28.8917px;
    font-weight: 500;
    font-size: 12.0328px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.200547px;
    color: #717579;
    li{
        padding-top: 6px;
        cursor: pointer;
    }
    .active{
        background: #201b37;
        border-radius: 28.8917px;
        color: #FFFFFF;
    }
}

.text-as{
    font-size: 15px;
    line-height: 11px;
}

.text-bs{
    font-size: 13px;
    line-height: 20px;
}

.text-cs{
    font-size: 11px;
    line-height: 20px;
}

.text-ds{
    font-size: 13px;
    line-height: 25px;
}

.text-es{
    font-size: 20px;
    line-height: 25px;
}

.text-fs{
    font-size: 17px;
    line-height: 25px;
}

.text-gs{
    font-size: 15px;
    line-height: 18px;
}
.text-hs{
    font-size: 12px;
    line-height: 15px;
}
.text-is{
    font-size: 15px;
    line-height: 25px;
}

.react-datepicker__input-container input{
    color: #495057 !important;
}

.activity-inp{
    height: 20px !important;
    padding: 0px 8px !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 9.78571px !important;
    line-height: 15px !important;
    letter-spacing: 0.21746px !important;
}

.kghk{
    font-size: 9.78571px;
    line-height: 15px;
    letter-spacing: 0.21746px;
    color: #495057;
}

.bkbg{
    border: 1.00814px solid #E4E6F6;
}

.abkbg{
    border: 1.00814px solid #C734E5;
}

.lndn-inpt{
    border: none;
    box-sizing: border-box;
    border-radius: 60.3198px;
    background-color: #F8F9FC;
}
.lndn-btn{
    outline: none;
    border: none;
    width: 110px;
    height: 40px;
    background: #C734E5;
    box-sizing: border-box;
    border-radius: 44.2345px;
    padding: 10px 13px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.160853px;

    input{
        background-color: #F8F9FC;
        border-radius: 60.3198px;
        outline: none;
    }
}

.br50{
    border-radius: 51.5267px;
}

.br5{
    border-radius: 5.84252px;
}

.br6{
    border-radius: 6.44654px;
}

.br3{
   border-radius: 3.29274px;
}

.br10{
    border-radius: 10px;
}

.layout{
    border: 0.555954px solid #E8E8E8;
    border-radius: 3.33573px;
}

.home{
    border-right: 1px solid #D8DCDE;

    .top{
        border-bottom: 1px solid #D8DCDE;
    }
}

.bb1{
    border-bottom: 0.700162px solid #E8E8E8;
}

.betcard{
    background: #F9FAFB;
    color: #9CA3AF;
    border: 0.778464px solid #3B82F6;
    border-radius: 4.67079px;

    :hover{
        background: #EFF6FF;
        color: #3B82F6;
    }
}
 
iframe {
    width: 100%;
    height: 670px;
    padding: 0;
    margin: 0;
    overflow-x: scroll;
}

.copy{
    display: flex;
    justify-content: center;
    border: 1px solid #D7C4D1;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 11.52px;
    line-height: 36px;
    box-sizing: border-box;
}

// .datePicker{
//     width: 100%;
//     height: 40px;
//     background: #FFFFFF;
//     border: 0.75px solid #A0A4A8;
//     border-radius: 6px;
//     padding: 13.5px 18px;
// }
// .react-datepicker-wrapper,
// .react-datepicker__input-container {
//     display: block !important;
//     width: 100%;
//     height: 40px;
//     background: #FFFFFF;
//     border: 0.75px solid #A0A4A8;
//     border-radius: 6px;
//     padding: 13.5px 18px;
// }

.react-datepicker__input-container input{
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 0.75px solid #A0A4A8;
    border-radius: 6px;
    padding: 13.5px 18px;
}

.betCard{
    width: 100%;
    height: 72px;
    border-radius: 4.67079px;
    padding: 18.5px 12px;
    display: flex;
    background: #F9FAFB;
    border: 0.778464px solid #D1D5DB;
    color: #9CA3AF;

    .hdr{
        font-weight: 600;
        font-size: 10.12px;
        line-height: 12px;
        letter-spacing: 0.155693px;
    }
    .dtls{
        font-weight: 500;
        font-size: 6.93191px;
        line-height: 9px;
        letter-spacing: 0.115532px;
    }
}

.betCard-active{
    background: #EFF6FF;
    border: 0.778464px solid #3B82F6;
    transform: translatey(-6%);
    transition: ease-out 0.2s;
    color: #3B82F6;
}

.betCard-active:hover{
    transform: translatey(-6%);
    transition: ease-out 0.2s;
}

.errtxt{
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.0604447px;
    text-decoration-line: underline;
    color: #E93C3C;
    text-align: center;
    cursor: pointer;
}
   
.tc{
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;

}
.cbt{
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.2px;
}
.nt{
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.0585586px;
    color: rgba(0, 0, 0, 0.5);
}
.label{
    width: 30px;
    height: 30px;
    background: #F3F4F6;
    border-radius: 50%;
    margin: 0px 18px 20px 0px;
    padding: 4px 11px;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
}

table .active, .inactive, .expired{
    text-align: center;
    border-radius: 45.2281px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 9.89364px;
    line-height: 13px;
    letter-spacing: 0.0706688px;
    width: 74px;
    height: 24px;
    box-sizing: border-box;
}

table .active{
    background: #FDE68A;
    color: #977E1A;
}

.activeStatus {
text-align: center;
    border-radius: 45.2281px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 9.89364px;
    line-height: 13px;
    letter-spacing: 0.0706688px;
    width: 74px;
    height: 24px;
    box-sizing: border-box;
    background: #FDE68A;
    color: #977E1A;
}

.inactive{
    background: #E8E8E8;
    color: rgba(0, 0, 0, 0.4);
}
.expired{
    background: #FECACA;
    color: #B91C1C;
}

html {
overflow: scroll;
overflow-x: hidden;
}
::-webkit-scrollbar {
width: 0px; /* remove scrollbar space */
background: transparent; /* optional: just make scrollbar invisible */
}
.msg{
    /* position: fixed;
    bottom: 20px; */
    height: 100vh;
    // height: calc(70vh - 100px);
    /* transform: translateY(-100%) translateY(58px); */
    transition: all .2s ease-out;
}
  
.msg-is-minimized{
transform: translateY(100%) translateY(-58px);
transition: all .2s ease-out;
}
  
.msg-icon-minimized{
transform: translateY(10%) rotate(180deg);
transition: transform .2s ease-out;
}
.radio {
  height: 19px;
  width: 19px;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #C734E5;
}

footer-txt{
  color: #5E5E5E;
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
}
.footer-txt:hover{
  color: #5E5E5E;
  color: #C734E5;
}

/* InputDate Component styles */

/* Hover Styles */
.hover-class:hover {
  background-color: #D0D0D0; /* Specify hover background color */
  cursor: pointer; /* Add a pointer cursor on hover */
}

/* Select Styles */
.select-class:focus {
  background-color: #A0A0A0; /* Specify select background color */
  outline: none; /* Remove default outline on focus */
}

/* FloatingButton Component styles */

/* FloatingButton.css */
.floating-button {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: #201b37;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 14px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add this CSS in your stylesheet or style tag */
.tooltip {
  background-color: #201b37;
  color: #fff;
  font-size: 14px;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.tooltip.show {
  visibility: visible;
}

.tooltip.hide {
  visibility: hidden;
}

/* CSS for mobile header */
.header {
    position: sticky;
    top: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    z-index: 999;
}

.slide-up {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

/* Define the class for sliding down */
.slide-down {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

.mobile-nav-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 997;
}

.slide-right {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

/* Define the class for sliding left */
.slide-left {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.no-transition {
  transition: none;
}

.tab-buttons {
    display: flex;
    align-items: center; /* Center items vertically */
    overflow-x: auto;
    gap: 8px; /* Adjust the gap between buttons as needed */
}

.tab-button {
    background-color: #201b37;
    border: solid 1px #201b37;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    flex-shrink: 0; /* Prevent buttons from shrinking when overflowed */
    line-height: 1.5; /* Adjust line-height to match button height */
}

.tab-button.active {
    background-color: #C734E5;
    border: solid 1px #C734E5;
}

.tab-button.secondary {
    background-color: #E8E8E8;
    color: #201b37;
    border: solid 1px #E8E8E8;
}

.tab-button.tertiary {
    background-color: #FFFFFF;
    color: #201b37;
    border: solid 1px #201b37;
}


.fixtures-stats {
background-color: #ffffff; /* Light background color */
border-radius: 8px; /* Rounded corners */
padding: 8px; /* Padding for spacing */
}

.fixture-row {
display: flex; /* Display fixtures in rows */
justify-content: space-between; /* Evenly space fixture info */
margin-bottom: 8px; /* Bottom margin for spacing between rows */
}

.fixture-info {
flex: 1; /* Distribute space evenly between fixture info */
}

.fixture-label {
font-weight: bold; /* Bold font for fixture labels */
margin-bottom: 4px; /* Bottom margin for spacing */
}

.goals-stats {
margin-top: 6px;
}

.goals-summary {
margin-bottom: 8px;
}

.goals-title {
font-weight: bold;
margin-bottom: 4px;
font-family: 'Montserrat', sans-serif;
}

.goals-detail {
margin-bottom: 2px;
font-family: 'Montserrat', sans-serif;
}

.goals-by-minute {
margin-top: 4px;
}

.goals-minute-item {
list-style: none;
margin-bottom: 2px;
}

.minute {
font-weight: bold;
margin-right: 4px;
font-family: 'Montserrat', sans-serif;
}

.total,
.percentage {
margin-left: 4px;
font-family: 'Montserrat', sans-serif;
}

.goals-chart {
display: flex;
justify-content: space-between;
margin-top: 6px;
}

.bar {
display: flex;
flex-direction: column;
align-items: center;
}

.bar-container {
width: 20px;
height: 150px; /* Max height of bars */
background-color: #f0f0f0; /* Background color of bar container */
}

.fill {
width: 100%;
background-color: #007bff; /* Bar fill color */
}

.bar-label {
font-size: 12px;
}

.bar-value {
margin-top: 5px;
font-size: 12px;
}

.streak-stats-chart {
align-items: center;
margin-top: 10px;
}

// Tool tip visibiliy on home
.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.animated-border {
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
        from var(--border-angle),
        #FFF,
        #FFF 5%,
        #FFF 60%,
        #FFF 95%
      );
    
    border: solid 1px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #C734E5, transparent 99%, transparent);
    
    background: 
      // padding-box clip this background in to the overall element except the border.
      var(--main-bg) padding-box,
      // border-box extends this background to the border space
      var(--gradient-border) border-box, 
      // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
  
    &:hover {
      animation-play-state: paused;
    }
}

.animated-border-dark {
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
        from var(--border-angle),
        #201b37,
        #201b37 5%,
        #201b37 60%,
        #201b37 95%
    );
    
    border: solid 2px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #C734E5, transparent 99%, transparent);
    
    background: 
      // padding-box clips this background into the overall element except the border.
      var(--main-bg) padding-box,
      // border-box extends this background to the border space.
      var(--gradient-border) border-box, 
      // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
  
    &:hover {
      animation-play-state: paused;
    }
}


.animated-border-card {
    border-radius: '10px';
    background-color: '#F7F7FC';
    margin-bottom: '16px';
    padding: '16px';
    box-shadow: '0 2px 4px rgba(0, 0, 0, 0.1)';
    word-wrap: 'break-word';
    
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
        from var(--border-angle),
        #FFF,
        #FFF 5%,
        #FFF 60%,
        #FFF 95%
      );
    
    border: solid 1px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #C734E5, transparent 99%, transparent);
    
    background: 
      // padding-box clip this background in to the overall element except the border.
      var(--main-bg) padding-box,
      // border-box extends this background to the border space
      var(--gradient-border) border-box, 
      // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
  
    &:hover {
      animation-play-state: paused;
    }
  }
  
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }

  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .otp-input {
    background-color: rgb(228, 228, 228);
    width: 30px;
    height: 30px;
    text-align: center;
    border: none;
    border-radius: 7px;
    caret-color: rgb(127, 129, 255);
    color: rgb(44, 44, 44);
    outline: none;
    font-weight: 600;
  }
  
  .otp-input:focus,
  .otp-input:valid {
    background-color: rgba(127, 129, 255, 0.199);
    transition-duration: .3s;
  }

.rotate-device-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 18px;
    z-index: 99;
    text-align: center;
    font-family: "Montserrat";
    opacity: 0;
    visibility: hidden;
    pointer-events: none; /* Prevent clicks when hidden */
    transform: translateY(-20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, visibility 0s 0.5s;
}

.rotate-device-message.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateY(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.semi-transparent {
    opacity: 0.5;
    transition: opacity 0.3s ease;
}











